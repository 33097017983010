<template>
  <div id="homePage">
    <h1 class="com_file">{{ msg }}</h1>
  </div>
  <div class="com_file">
    <a-col
      :span="24"
      style="
        margin-top: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <a-statistic-countdown
        title="预估下一期开发周期"
        :value="deadline"
        format="D 天 H 时 m 分 s 秒"
      />
    </a-col>
  </div>
  <div class="com_file">
    <a-collapse accordion>
      <a-collapse-panel
        key="1"
        header="成功的人是从失败中走出来的，而不是从成功中走出来的"
      >
        <p>{{ text1 }}</p>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="你不能改变过去，但你可以改变未来。">
        <p>{{ text2 }}</p>
      </a-collapse-panel>
      <a-collapse-panel key="3" header="不要等待机会，而是创造机会。">
        <p>{{ text3 }}</p>
      </a-collapse-panel>
      <a-collapse-panel key="4" header="《樱花下的约定》">
        <p>{{ text4 }}</p>
      </a-collapse-panel>
    </a-collapse>
  </div>

  <div>
    <a-upload-dragger
      v-model:fileList="fileList"
      name="file"
      :multiple="true"
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      @change="handleChange"
      @drop="handleDrop"
    >
      <p class="ant-upload-drag-icon">
        <inbox-outlined></inbox-outlined>
      </p>
      <p class="ant-upload-text">明凡云上传样例</p>
      <p class="ant-upload-hint">这是一个上传demo样例，等待后端API接入。</p>
    </a-upload-dragger>
  </div>
</template>

<script setup lang="ts">
const msg = "明凡云开发中，敬请期待";
const text1 = `亨利·福特是美国的工业家、工程师、发明家，也是福特汽车公司的创始人。他的这句名言强调了失败对于成功的重要性。福特本人在成功之前也经历了多次失败，他的早期汽车公司如底特律汽车公司和亨利·福特公司都未能成功。但他没有放弃，最终在1903年与其他人一起创立了福特汽车公司，并推出了革命性的T型车，这不仅改变了汽车行业，也推动了现代工业生产方式的发展`;
const text2 = `珍妮特·克莱尔是一位作家和诗人，她的这句话提醒我们，虽然我们不能改变过去，但我们可以通过当前的行动来塑造我们的未来。这句话鼓励人们专注于现在，通过积极的态度和行动来改善自己的生活。虽然关于珍妮特·克莱尔的详细信息不多，但她的这句话被广泛引用，激励着人们向前看，积极面对生活。`;
const text3 = `乔治·伯纳德·肖，通常被称为G.B. Shaw，是爱尔兰剧作家和评论家，1925年诺贝尔文学奖得主。他以尖锐的社会评论和机智的对话而闻名。他的这句名言鼓励人们主动出击，而不是被动等待机会的到来。肖本人就是一个不断寻求改变和创新的人，他的作品经常探讨社会和政治问题，推动社会进步。他的戏剧作品，如《圣女贞德》和《皮格马利翁》，都体现了他对传统观念的挑战和对个人主动性的强调。`;
const text4 = `
### 第一章：樱花的邂逅

在一个春意盎然的小镇上，樱花如云似霞地绽放着。小镇的一角，有一家名为“樱花屋”的小书店，那里住着一个名叫小樱的可爱女孩。小樱有着一头柔顺的长发，眼睛像清澈的湖水，总是带着温柔的微笑。她对书籍有着无比的热爱，每个午后，她都会坐在书店的窗边，沉浸在书页间的世界里。

一天，阳光透过窗户，洒在她的发梢，为她镀上了一层金色的光晕。这时，门铃轻响，一个名叫林风的男孩走了进来。他是一个旅行作家，带着对世界的好奇和对未知的渴望，四处漂泊。

林风的目光被小樱专注的神情所吸引，他走到她身边，轻声问道：“这本书好看吗？”小樱抬起头，眼中闪过一丝惊讶，随即微笑回答：“这是一本关于远方的故事，每个字都充满了冒险和奇遇。”两人就这样聊了起来，从书籍到旅行，从梦想到现实，仿佛有说不完的话题。

林风被小樱的纯真和热情所打动，而小樱也被林风的故事所吸引。两人在樱花屋的书架间穿梭，分享着彼此的故事和梦想。随着夕阳的余晖洒满书店，林风告别了小樱，但他知道，这只是他们故事的开始。

### 第二章：书页间的秘密

随着时间的推移，林风成了樱花屋的常客。他每次旅行回来，都会带给小樱一些新奇的小物件，而小樱则会为他准备一杯热腾腾的咖啡，两人的关系在不知不觉中悄然变化。

有一天，林风在一本书中发现了一张泛黄的纸条，上面写着：“在樱花最灿烂的时候，我会在树下等你。”他知道，这是小樱留给他的暗示。林风的心中涌起了一股暖流，他决定在樱花节那天，给小樱一个惊喜。

小樱也在默默地期待着那一天的到来。她开始在书店的角落里，为林风准备一份特别的礼物。每当夜深人静，她都会在一盏温暖的灯光下，小心翼翼地包装着这份心意。

### 第三章：樱花下的约定

樱花节的那天，整个小镇都沉浸在粉色的花海中。小樱站在樱花树下，手中紧握着一本未完待续的书。她知道，林风会来。当林风出现在她的视线中时，她的心情如同飞舞的樱花瓣，既激动又期待。

林风走到她面前，从背后拿出一束樱花，温柔地说：“我愿意和你一起，写完这本书的每一个章节。”小樱的眼中闪烁着泪光，她接过樱花，轻轻地点了点头。两人在樱花树下许下了永恒的约定，他们的心紧紧相连。

### 第四章：爱的旅程

从那天起，林风和小樱开始了他们共同的旅程。林风的旅行故事里，从此多了一个小樱的身影。他们在书页间留下了爱的足迹，每个章节都充满了甜蜜和温馨。樱花屋成了他们爱情故事的起点，也是他们共同梦想的港湾。

小樱开始在书店里举办读书会，分享她和林风的故事。林风则在旅行中，记录下他们的点点滴滴，将这些故事写成书，让更多的人感受到他们的爱情。

岁月流转，樱花屋依旧静静地伫立在小镇的一角，见证着小樱和林风的爱情。他们的故事，就像那些被翻阅过无数次的书籍，随着时间的流逝，愈发珍贵和动人。

### 结语

爱情，有时候就像一本未完待续的书，需要两个人一起书写。小樱和林风的故事，就像那樱花树下的约定，美丽而永恒。在这个充满书香和樱花香气的小镇上，他们的爱情，如同那些被翻阅过无数次的书籍，随着时间的流逝，愈发珍贵和动人。愿每个读到这个故事的人，都能找到属于自己的幸福和温暖。
`;
import { ref } from "vue";
import { InboxOutlined } from "@ant-design/icons-vue";
import type { UploadChangeParam } from "ant-design-vue";
import { message } from "ant-design-vue";

const fileList = ref([]);
const handleChange = (info: UploadChangeParam) => {
  const status = info.file.status;
  if (status !== "uploading") {
    console.log(info.file, info.fileList);
  }
  if (status === "done") {
    message.success(`${info.file.name} file uploaded successfully.`);
  } else if (status === "error") {
    message.error(`${info.file.name} file upload failed.`);
  }
};
function handleDrop(e: DragEvent) {
  console.log(e);
}

/**
 * OBS 文件上传实例
 */
/**
 * // 引入obs库
 * // 使用npm安装
 * const ObsClient = require("esdk-obs-nodejs");
 * // 使用源码安装
 * // var ObsClient = require('./lib/obs');
 *
 * // 创建ObsClient实例
 * const obsClient = new ObsClient({
 *   // 推荐通过环境变量获取AKSK，这里也可以使用其他外部引入方式传入，如果使用硬编码可能会存在泄露风险
 *   // 您可以登录访问管理控制台获取访问密钥AK/SK，获取方式请参见https://support.huaweicloud.com/intl/zh-cn/usermanual-ca/ca_01_0003.html
 *   access_key_id: process.env.1DTJLPUWDVCZDBKB8HZ3,
 *   secret_access_key: process.env.rkhQTsDMA85gAlQlBQeLhD91PWrmCrlBJuBmtiBx,
 *   // 【可选】如果使用临时AK/SK和SecurityToken访问OBS，同样建议您尽量避免使用硬编码，以降低信息泄露风险。您可以通过环境变量获取访问密钥AK/SK，也可以使用其他外部引入方式传入
 *   // security_token: process.env.SECURITY_TOKEN,
 *   // endpoint填写Bucket对应的Endpoint, 这里以中国-香港为例，其他地区请按实际情况填写
 *   server: "https://obs.ap-southeast-1.myhuaweicloud.com"
 * });
 *
 * async function putObject() {
 *   try {
 *     const params = {
 *       // 指定存储桶名称
 *       Bucket: "mingfancloud-project-pic",
 *       // 指定对象名，此处以 example/objectname 为例
 *       Key: "example/objectname",
 *       // localfile为待上传的本地文件路径，需要指定到具体的文件名
 *       SourceFile : 'localfile'
 *     };
 *     // 文件上传
 *     const result = await obsClient.putObject(params);
 *     if (result.CommonMsg.Status <= 300) {
 *       console.log("Put bucket(%s) successful!", params.Bucket);
 *       console.log("RequestId: %s", result.CommonMsg.RequestId);
 *       return;
 *     };
 *     console.log("An ObsError was found, which means your request sent to OBS was rejected with an error response.");
 *     console.log("Status: %d", result.CommonMsg.Status);
 *     console.log("Code: %s", result.CommonMsg.Code);
 *     console.log("Message: %s", result.CommonMsg.Message);
 *     console.log("RequestId: %s", result.CommonMsg.RequestId);
 *   } catch (error) {
 *     console.log("An Exception was found, which means the client encountered an internal problem when attempting to communicate with OBS, for example, the client was unable to access the network.");
 *     console.log(error);
 *   };
 * };
 *
 * putObject();
 */

/**
 * 倒计时组件获取距离秒数
 * 示例
 * const deadline = Date.now() + 1000 * 60 * 60 * 24 * 14;
 */
// 创建一个 Date 对象，表示 2024 年 12 月 30 日
const targetDate = new Date(2024, 11, 30); // 月份是从 0 开始的，所以 11 表示 12 月
// 获取目标日期的时间戳
const targetTimestamp = targetDate.getTime();

// 获取当前时间的时间戳
const nowTimestamp = Date.now();
console.log(nowTimestamp);
// 计算距离目标日期的毫秒数

const deadline = Date.now() + targetTimestamp - nowTimestamp;
</script>

<style scoped>
.com_file {
  margin-bottom: 40px;
}
#homePage {
}
</style>
