import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "userLoginPage" }

import { reactive } from "vue";
import { userLogin } from "@/api/user";
import { useLoginUserStore } from "@/store/useLoginUserStore";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";

interface FormState {
  userAccount: string;
  userPassword: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLoginPage',
  setup(__props) {

const formState = reactive<FormState>({
  userAccount: "",
  userPassword: "",
});

const router = useRouter();
const loginUserStore = useLoginUserStore();

/**
 * 提交表单
 * @param values
 */
const handleSubmit = async (values: any) => {
  const res = await userLogin(values);
  // 登录成功，把登录态保存到全局状态中
  if (res.data.code === 0 && res.data.data) {
    await loginUserStore.fetchLoginUser();
    message.success("登录成功");
    router.push({
      path: "/",
      replace: true,
    });
  } else {
    message.error("登录失败");
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "title" }, "用户登录", -1)),
    _createVNode(_component_a_form, {
      style: {"max-width":"480px","margin":"0 auto"},
      model: formState,
      name: "basic",
      "label-align": "left",
      "label-col": { span: 4 },
      "wrapper-col": { span: 20 },
      autocomplete: "off",
      onFinish: handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          label: "账号",
          name: "userAccount",
          rules: [{ required: true, message: '请输入账号' }]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: formState.userAccount,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((formState.userAccount) = $event)),
              placeholder: "请输入账号"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "密码",
          name: "userPassword",
          rules: [
          { required: true, message: '请输入密码' },
          { min: 8, message: '密码不能小于 8 位' },
        ]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_password, {
              value: formState.userPassword,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((formState.userPassword) = $event)),
              placeholder: "请输入密码"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { "wrapper-col": { offset: 4, span: 20 } }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("登录")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})