import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment, unref as _unref, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { id: "userManagePage" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import { deleteUser, searchUsers } from "@/api/user";
import { ref } from "vue";
import { message } from "ant-design-vue";
import dayjs from "dayjs";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserManagePage',
  setup(__props) {

const searchValue = ref("");
// 获取数据
const onSearch = () => {
  fetchData(searchValue.value);
};

// 删除数据
const doDelete = async (id: string) => {
  if (!id) {
    return;
  }
  const res = await deleteUser(id);
  if (res.data.code === 0) {
    message.success("删除成功");
  } else {
    message.error("删除失败");
  }
};

const columns = [
  {
    title: "id",
    dataIndex: "id",
  },
  {
    title: "用户名",
    dataIndex: "username",
  },
  {
    title: "账号",
    dataIndex: "userAccount",
  },
  {
    title: "头像",
    dataIndex: "avatarUrl",
  },
  {
    title: "性别",
    dataIndex: "gender",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
  },
  {
    title: "用户角色",
    dataIndex: "userRole",
  },
  {
    title: "操作",
    key: "action",
  },
];

// 数据
const data = ref([]);

// 获取数据
const fetchData = async (username = "") => {
  const res = await searchUsers(username);
  if (res.data.data) {
    data.value = res.data.data;
  } else {
    message.error("获取数据失败");
  }
};

fetchData();

return (_ctx: any,_cache: any) => {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_input_search, {
      style: {"max-width":"320px","margin-bottom":"20px"},
      value: searchValue.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((searchValue).value = $event)),
      placeholder: "输入用户名搜索",
      "enter-button": "搜索",
      size: "large",
      onSearch: onSearch
    }, null, 8, ["value"]),
    _createVNode(_component_a_table, {
      columns: columns,
      "data-source": data.value
    }, {
      bodyCell: _withCtx(({ column, record }) => [
        (column.dataIndex === 'avatarUrl')
          ? (_openBlock(), _createBlock(_component_a_image, {
              key: 0,
              src: record.avatarUrl,
              width: 120
            }, null, 8, ["src"]))
          : (column.dataIndex === 'userRole')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (record.userRole === 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_a_tag, { color: "green" }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("管理员")
                        ])),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_a_tag, { color: "blue" }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("普通用户")
                        ])),
                        _: 1
                      })
                    ]))
              ], 64))
            : (column.dataIndex === 'createTime')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createTextVNode(_toDisplayString(_unref(dayjs)(record.createTime).format("YYYY-MM-DD HH:mm:ss")), 1)
                ], 64))
              : (column.key === 'action')
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 3,
                    danger: "",
                    onClick: ($event: any) => (doDelete(record.id))
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("删除")
                    ])),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["data-source"])
  ]))
}
}

})