<template>
  <div id="app">
    <BasicLayout />
  </div>
</template>
<script lang="ts" setup>
import BasicLayout from "@/layouts/BasicLayout.vue";
import { useLoginUserStore } from "@/store/useLoginUserStore";
const loginUserStore = useLoginUserStore();
loginUserStore.fetchLoginUser();
</script>
<style></style>
