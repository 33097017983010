import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "userRegisterPage" }

import { reactive } from "vue";
import { userRegister } from "@/api/user";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";

interface FormState {
  userAccount: string;
  userPassword: string;
  checkPassword: string;
  planetCode: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserRegisterPage',
  setup(__props) {

const formState = reactive<FormState>({
  userAccount: "",
  userPassword: "",
  checkPassword: "",
  planetCode: "",
});

const router = useRouter();

/**
 * 提交表单
 * @param values
 */
const handleSubmit = async (values: any) => {
  // 判断两次输入的密码是否一致
  if (formState.userPassword !== formState.checkPassword) {
    message.error("二次输入的密码不一致");
    return;
  }
  const res = await userRegister(values);
  // 注册成功，跳转到登录页面
  if (res.data.code === 0 && res.data.data) {
    message.success("注册成功");
    router.push({
      path: "/user/login",
      replace: true,
    });
  } else {
    message.error("注册失败，" + res.data.description);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "title" }, "用户注册", -1)),
    _createVNode(_component_a_form, {
      style: {"max-width":"480px","margin":"0 auto"},
      model: formState,
      name: "basic",
      "label-align": "left",
      "label-col": { span: 4 },
      "wrapper-col": { span: 20 },
      autocomplete: "off",
      onFinish: handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          label: "账号",
          name: "userAccount",
          rules: [{ required: true, message: '请输入账号' }]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: formState.userAccount,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((formState.userAccount) = $event)),
              placeholder: "请输入账号"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "密码",
          name: "userPassword",
          rules: [
          { required: true, message: '请输入密码' },
          { min: 8, message: '密码不能小于 8 位' },
        ]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_password, {
              value: formState.userPassword,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((formState.userPassword) = $event)),
              placeholder: "请输入密码"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "确认密码",
          name: "checkPassword",
          rules: [
          { required: true, message: '请输入确认密码' },
          { min: 8, message: '确认密码不能小于 8 位' },
        ]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_password, {
              value: formState.checkPassword,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((formState.checkPassword) = $event)),
              placeholder: "请输入确认密码"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "编号",
          name: "planetCode",
          rules: [{ required: true, message: '请输入编号' }]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: formState.planetCode,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((formState.planetCode) = $event)),
              placeholder: "请输入编号"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { "wrapper-col": { offset: 4, span: 20 } }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("注册")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})