<template>
  <div id="homePage">
    <h1 class="com_file">{{ msg }}</h1>
  </div>
  <div class="com_file">
    <a-col
      :span="24"
      style="
        margin-top: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <a-statistic-countdown
        title="距离除夕还剩"
        :value="deadline"
        format="D 天 H 时 m 分 s 秒"
      />
    </a-col>
  </div>
</template>
<script setup lang="ts">
const msg = "明凡云开发中，敬请期待";

/**
 * 倒计时组件获取距离秒数
 * 示例
 * const deadline = Date.now() + 1000 * 60 * 60 * 24 * 14;
 */
// 创建一个 Date 对象，表示 2024 年 12 月 30 日
const targetDate = new Date(2025, 0, 28); // 月份是从 0 开始的，所以 11 表示 12 月
// 获取目标日期的时间戳
const targetTimestamp = targetDate.getTime();

// 获取当前时间的时间戳
const nowTimestamp = Date.now();
console.log(nowTimestamp);
// 计算距离目标日期的毫秒数

const deadline = Date.now() + targetTimestamp - nowTimestamp;
</script>
<style scoped></style>
