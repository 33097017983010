import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

import BasicLayout from "@/layouts/BasicLayout.vue";
import { useLoginUserStore } from "@/store/useLoginUserStore";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const loginUserStore = useLoginUserStore();
loginUserStore.fetchLoginUser();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BasicLayout)
  ]))
}
}

})