import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "basicLayout" }

import GlobalHeader from "@/components/GlobalHeader.vue";

//登陆状态信息
// import { useLoginUserStore } from "@/store/useLoginUserStore";
// const loginUserStore = useLoginUserStore();
//测试代码
// import { getCurrentUser } from "@/api/user";
// getCurrentUser().then((res) => {
//   console.log(res);
// });

export default /*@__PURE__*/_defineComponent({
  __name: 'BasicLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_a_back_top = _resolveComponent("a-back-top")!
  const _component_a_float_button_group = _resolveComponent("a-float-button-group")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_layout, { style: {"min-height":"100vh"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_header, { class: "header" }, {
          default: _withCtx(() => [
            _createVNode(GlobalHeader)
          ]),
          _: 1
        }),
        _createVNode(_component_a_layout_content, { class: "content" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_float_button_group, {
              shape: "circle",
              style: { right: '24px' }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_back_top, { "visibility-height": 0 })
              ]),
              _: 1
            }),
            _createVNode(_component_router_view)
          ]),
          _: 1
        }),
        _createVNode(_component_a_layout_footer, { class: "footer" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("a", {
              href: "https://mingfancloud.cn",
              target: "_blank"
            }, " 明凡云 by 明凡 ", -1)
          ])),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})